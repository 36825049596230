import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

export default () => (
  <Container>
    <Row id="home-page-header">
      <Col>
        <table style={{ height: "90%", width: "100%" }}>
          <tbody>
            <tr className="text-center">
              <td className="align-bottom"><h1 style={{ color: "white" }}>Craft Malt for Craft Brewers</h1></td>
            </tr>
          </tbody>
        </table>
      </Col>
    </Row>
    <Row>
      <Col className="text-center">
        <br></br>
        <h2>Tradition is a great thing ... so is local!</h2>
        <h2>Locally produced malt in the traditional way by floor malting.</h2>
      </Col>
    </Row>
    <Row>
      <Container className="body-content">
        <Row>
          <Col md={4} className="text-center">
            <h2><i className="fa fa-map-marker fa-5x"></i></h2>
            <h2>Local</h2>
            <p>Produced in Atlantic Canada from grains grown in PEI, New Brunswick and Nova Scotia. </p>
          </Col>
          <Col md={4} className="text-center">
            <h2><i className="fa fa-beer fa-5x"></i></h2>
            <h2>Craft</h2>
            <p>Handcrafted and floor malted in the traditional way by members of the Craft Maltsters Guild.</p>
          </Col>
          <Col md={4} className="text-center">
            <h2><i className="fa fa-clipboard fa-5x"></i></h2>
            <h2>Quality</h2>
            <p>Each batch is tested by Hartwick College in Oneonta, NY to ensure consistency.</p>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col md={6}>
            <h2 className="text-center title">Home Brewers</h2>
            <p className="text-center">You can now get Island Malt House products for your next brewing project at <a href="https://eastcoasthoppers.ca/" target="_blank">East Coast Hoppers</a>.</p>
          </Col>
          <Col md={6}>
            <a href="https://eastcoasthoppers.ca/" target="_blank"><img src="/images/eastcoasthoppers.png"></img></a>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col>
            <h2 className="text-center title">Our Customers</h2>
          </Col>
        </Row>
        <Row className="align-items-center text-center">
          <Col sm={6} md={4}>
            <img className="customerLogo" src="/images/2crows.png"></img>
            <p><a href="https://www.2crowsbrewing.com/" target="_blank">2crowsbrewing.com</a></p>
          </Col>
          <Col sm={6} md={4}>
            <img className="customerLogo" src="/images/badapple.png"></img>
            <p><a href="https://badapplebrewhouse.ca/" target="_blank">badapplebrewhouse.ca</a></p>
          </Col>
          <Col sm={6} md={4}>
            <img className="customerLogo" src="/images/bogside.png"></img>
            <p><a href="https://www.facebook.com/pages/category/Brewery/Bogside-Brewing-673301803060621/" target="_blank">facebook.com/bogsidebrewing</a></p>
          </Col>
          <Col sm={6} md={4}>
            <img className="customerLogo" src="/images/cavok.png"></img>
            <p><a href="https://cavokbrewing.ca/" target="_blank">cavokbrewing.ca</a></p>
          </Col>
          <Col sm={6} md={4}>
            <img className="customerLogo" src="/images/loneoak.jpg"></img>
            <p><a href="https://loneoakbrew.com/" target="_blank">loneoakbrew.com</a></p>
          </Col>
          <Col sm={6} md={4}>
            <img className="customerLogo" src="/images/CopperBottom.png"></img>
            <p><a href="https://www.copperbottombrewing.com/" target="_blank">www.copperbottombrewing.com</a></p>
          </Col>
          <Col sm={6} md={4}>
            <Container align-middle>
              <img className="customerLogo" src="/images/Gagetown_Distillery_Cidery.png"></img>
              <p><a href="https://gagetowndistillingcidery.ca/" target="_blank">gagetowndistillingcidery.ca</a></p>
            </Container>
          </Col>
          <Col sm={6} md={4}>
            <Container align-middle>
              <img className="customerLogo" src="/images/Gahan.png"></img>
              <p><a href="https://gahan.ca/" target="_blank">www.gahan.ca</a></p>
            </Container>
          </Col>
          <Col sm={6} md={4}>
            <img className="customerLogo" src="/images/Gridiron.png"></img>
            <p><a href="http://www.gridironbrewing.com" target="_blank">www.gridironbrewing.com</a></p>
          </Col>
          <Col sm={6} md={4}>
            <img className="customerLogo" src="/images/HolyWhale.jpg"></img>
            <p><a href="https://www.facebook.com/buddhabearcafe/" target="_blank">facebook.com/buddhabearcafe</a></p>
          </Col>
          <Col sm={6} md={4}>
            <img className="customerLogo" src="/images/SussexAle.jpg"></img>
            <p><a href="https://sussexaleworks.ca" target="_blank">www.sussexaleworks.ca</a></p>
          </Col>
          <Col sm={6} md={4}>
            <img className="customerLogo" src="/images/tcb.png"></img>
            <p><a href="https://tcb.beer/" target="_blank">tcb.beer</a></p>
          </Col>
          <Col sm={6} md={4}>
            <img className="customerLogo" src="/images/Triders.jpg"></img>
            <p><a href="http://www.triderscraftbeer.ca" target="_blank">www.triderscraftbeer.ca</a></p>
          </Col>
          <Col sm={6} md={4}>
            <img className="customerLogo" src="/images/upstreet.png"></img>
            <p><a href="https://upstreet.ca/" target="_blank">www.upstreet.ca</a></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="title">Ordering</h2>
            <p>Serving both large and small customers in Atlantic Canada. Malt is shipped in 25kg or 1000kg bags. Please visit the contact page or call 902-964-3022 to enquire.</p>
          </Col>
        </Row>
        <Row>
          <h2 className="title">Island Malt House in the News</h2>
        </Row>
        <Row>
          <Col md={4}>
            <img src="https://i.cbc.ca/1.5083255.1554316587!/fileImage/httpImage/image.jpeg_gen/derivatives/16x9_780/redmer-renkema-with-island-malt-house-malt.jpeg"></img>
          </Col>
          <Col md={6}>
            <h4>Made-in-P.E.I. malt could be coming soon to a beer near you</h4>
            <a href="https://www.cbc.ca/news/canada/prince-edward-island/pei-malt-malting-shoreline-business-beer-brewing-1.5081831">Read the full story on CBC</a>
          </Col>
        </Row>
      </Container>
    </Row>
  </Container>
)